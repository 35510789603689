@tailwind base;
@tailwind components;
@tailwind utilities;


/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */



/* Horizontal layout adjustments */
.timeline-container {
  width: 100%;
  overflow-x: scroll;
  padding: 20px;
  white-space: nowrap;
}

.horizontal-scroll {
  display: flex;
  flex-direction: row;
}

.vertical-timeline {
  flex-direction: row !important; /* Horizontal layout */
}

.vertical-timeline::before {
  background: none !important; /* Remove vertical line */
}

.vertical-timeline-element {
  display: inline-block;
  width: 300px;
  margin: 0 20px;
}

.vertical-timeline-element-content {
  text-align: center;
}

/* Icon adjustments */
.vertical-timeline-element-icon {
  margin-left: auto;
  margin-right: auto;
}

/* Title and subtitle */
.timeline-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 10px;
}

.timeline-subtitle {
  text-align: center;
  font-size: 16px;
  color: #5c5b5b;
  margin-bottom: 20px;
}
