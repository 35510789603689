/* slick-carousel styles */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


body {
    text-align: center;
}

html, body {
    overflow-y: scroll !important;
}

html {
    overflow: visible !important;
    padding-right: 0 !important;
}

/* Timeline */
.rc-card-text {
    display: none !important;
}

.using-icon {
    background-color: #008894 !important;
    padding: 0.5rem;
    border: 4px solid #D2D1D1;
}

.timeline-card-content header {
    /* height: 100% !important; */
}

.timeline-card-content header h1 {
    color: white !important;
    /* line-height: 2rem !important; */
    text-align: center !important;
    line-height: initial;
}

.timeline-card-content {
    justify-content: center !important;
    /* min-height: unset !important; */
}

li[aria-label="timeline_popover"] {
    display: none;
}

.vertical_alternating div[position="top"].ToolbarWrapper-sc-cif21b-6 {
    order: 2 !important;
}

.vertical_alternating div[position="top"] ul.ToolbarWrapper-sc-exupb5-0 {
    justify-content: center;
    background-color: unset;
    box-shadow: unset;
}

.vertical_alternating div[position="top"] ul.ToolbarWrapper-sc-exupb5-0 .timeline-controls li button {
    background-color: rgb(48, 44, 124);
}

/* Timeline */


@media (max-width:768px) {
    h1[data-class="rc-card-title"] {
        font-size: medium !important;
    }

    div[role="list"]>li .timeline-card-content header h1:first-child {
        font-size: small !important;
    }

    div[role="list"]>li:nth-child(odd) .timeline-card-content header h1:first-child {
        color: #EE6A29 !important;
        font-weight: bolder;
        /* text-decoration: underline 2px ; */
        /* text-underline-offset: 0.25rem; */
    }

    div[role="list"]>li:nth-child(even) .timeline-card-content header h1:first-child {
        color: #2C2A7A !important;
        font-weight: bolder;
        /* text-decoration: underline 2px ; */
        /* text-underline-offset: 0.25rem; */
    }

    .timeline-card-content {
        min-height: 50px !important;
    }
}

div[role="list"]>li:nth-child(odd) .timeline-card-content {
    background-color: #2C2A7A;
}

div[role="list"]>li:nth-child(odd) span {
    background-color: #2C2A7A;
}

/* BG Images */
/* Home */
@media (min-width:768px) {

    .home--her-bg {
        /* background-image: none !important; */
    }
}

/* Home */
/* BG Images */

/* Timeline */
/* Hide scrollbar for Chrome, Safari and Opera */
#timeline .timeline-main-wrapper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#timeline .timeline-main-wrapper {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* Timeline */

/* More About kaigal */
#more-about-kaigal iframe {
    height: 100% !important;
    width: 100% !important;
}

/* More About kaigal */

.pswp__img {
    /* max-height: 90dvh !important;
    width: auto !important; */
    object-fit: contain !important;
}



.chatbot-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}